<template>
  <div>
    <div class="type-box">
      <div
        v-for="(item, index) in ['普通考试', '专题考试']"
        :key="index"
        class="_TypeBox"
        :class="{
          action: typeBtnIndex == index,
        }"
        @click="changeTypeBtn(index)"
      >
        {{ item }}
      </div>
      <el-radio style="margin-left: 30px" v-model="radio" label="0"
        >全部</el-radio
      >
      <el-radio v-model="radio" label="1">按当前登陆人查询</el-radio>
      <el-radio v-if="typeBtnIndex != 1" v-model="radio" label="2"
        >按公司部门查询</el-radio
      >
      <div class="_Select" v-if="radio == 2">
        <span>公司：</span>
        <el-select
          v-model="company"
          @change="changCompny"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in $store.state.companyUserList"
            :key="item.id"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div v-if="radio == 2">
        <span>部门：</span>
        <el-select v-model="dept" clearable placeholder="请选择">
          <el-option
            v-for="item in deptList"
            :key="item.value"
            :label="item.deptmentName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <el-button style="margin-left: 10px" type="success" @click="getTableData"
        >搜索</el-button
      >
    </div>
    <div class="_Table">
      <el-table
        id="table"
        :height="elementHeight"
        :data="tableData.slice(start, end)"
        style="width: 100%"
        :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
      >
        <el-table-column prop="compName" label="公司"> </el-table-column>
        <el-table-column prop="deptName" label="部门" width="200">
        </el-table-column>
        <el-table-column prop="postName" label="岗位" width="200">
        </el-table-column>
        <el-table-column prop="examUserName" label="考试人员" width="130">
        </el-table-column>
        <el-table-column prop="saveTime" label="保存时间" width="180">
          <template slot-scope="scope">
            {{ scope.row.saveTime.split('T')[0] }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="考试状态" width="130">
          <template slot-scope="scope">
            <span>{{ scope.row.status == 2 ? '已完成' : '未完成' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="examScore" label="得分" width="130">
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="curSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        class="_Pagination"
        style="right: 20px; bottom: 20px"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { get } from '../../api/http.js'
export default {
  data() {
    return {
      radio: '0',
      company: '',
      companyUserList: [],
      dept: '',
      deptList: [],
      tableData: [],
      elementHeight: 0,
      typeBtnIndex: 0,
      start: 0,
      end: 10,
      curSize: 10,
      currentPage: 1,
    }
  },
  mounted() {
    this.getElementHeight()
    this.getTableData()
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 130)
      })
    },
    changCompny() {
      this.getDeptList()
    },
    getDeptList() {
      get('/api/Department/GetNoTree?CompId=' + this.company).then((res) => {
        if (res.code == 200) {
          this.deptList = res.data
        }
      })
    },
    changeTypeBtn(e) {
      this.typeBtnIndex = e
    },
    async getTableData() {
      var url = ''
      var userId = JSON.parse(sessionStorage.getItem('userInfo')).id
      if (this.typeBtnIndex == 0) {
        switch (this.radio * 1) {
          case 0:
            url = '/api/ExamPlan/GetList'
            break
          case 1:
            url = '/api/ExamPlan/GetByUserId?UserId=' + userId
            break
          case 2:
            url =
              '/api/ExamPlan/GetByCompId?CompId=' +
              this.company +
              '&DeptId=' +
              this.dept
            break
        }
      } else {
        url =
          this.radio == '0'
            ? '/api/ThemeExamPlan/GetList'
            : '/api/ThemeExamPlan/GetByUserId?UserId=' + userId
      }
      await get(url).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data
          this.currentPage = 1
          this.handleCurrentChange(1)
        }
      })
    },
    // 分页
    handleSizeChange(e) {
      this.curSize = e
      this.start = (this.currentPage - 1) * this.curSize
      this.end = this.start + this.curSize
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize
      this.end = this.start + this.curSize
    },
  },
}
</script>

<style lang="less" scoped>
.action {
  background: #5b79ae !important;
  color: #ffffff !important;
}
.type-box {
  display: flex;
  padding-bottom: 10px;
  align-items: center;
  padding-left: 20px;
  ._Select {
    margin-right: 20px;
    span {
      width: 60px !important;
    }
  }
}
</style>